import React, {Component} from 'react';
import Header from '../../Common/Components/Header/header.jsx';
import Donations from '../../Common/Components/DonationPanel/donationPanel.jsx';
import Footer from '../../Common/Components/Footer/footer.jsx';
import PageTitle from '../../Common/Components/PageTitle/pageTitle.jsx';
import PDFLink from '../../Common/Components/PDFLink/pdfLink.jsx';
import TopImage from '../../Common/assets/images/donations/topImage.JPG';
import BobyTaylor from '../../Common/assets/images/bobby taylor.jpg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cs from './campgroundReservation.module.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

import MainCampground from "../../Common/assets/images/campground/mainCampground.jpg"
import CampgroundSiteMap from "../../Common/assets/images/campground/campground site map.jpg"

class CampgroundReservation extends Component {
  render(){
    return (
      <div>
        <Header/>
        <Container>
          <PageTitle title="Campground Reservations"/>
          <img className={cs.top_img} src={MainCampground}/>          
          <p>
            Our property is open <b>ONLY</b> to members and sponsered youth groups. 
            <b>
              Note: Members must be present with their guests the entire time that their guests are using the SCCPOA facilities/property.
            </b> 
          </p>
          <p>
            <b>
              The second Saturday of each month is our WORK PARTY DAY. General use of the park is not recommended and the campground and range are closed. 
            </b> 
            <a href="/volunteer"> Feel free to come up and lend a hand with maintenance and improvements.</a>
          </p>

            <p>
              Please adhere to all use/safety rules at all and think of the consequences of your actions because contrary to popular belief, from time to time both the pistol range and the campgrounds suffer from what can only be called “willful negligence.”
            </p>
            <p>
               Any violations should be reported to the administrative offices / President Bud Smith, at (408) 264-1224
            </p>
            <br/>

            <h3>
                Please click below which camping reservation applies to you:
            </h3>

          {/* <Accordion className={cs.accordion} defaultActiveKey="1">*/}
          <Accordion className={cs.accordion} >
            <Card className={cs.card_border}>
              <Accordion.Toggle className={cs.toggle} as={Card.Header} eventKey="1">  Members & Immediate family </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <br/>
                      <p>
                        If you are a member, you and your family can camp on the property for free. <a href="https://www.google.com/calendar/embed?src=hracing16%40gmail.com&ctz=America/Los_Angeles"> Please check the SCCPOA calendar for available dates. <FontAwesomeIcon className={cs.make_blue}  icon={'calendar-alt'} /></a>
                      </p>
                      <p>
                        After checking our availability, please fill out and send the following forms to propertyscheduling@sccpoa.org.
                        <br/>
                        <br/>
                      </p>

                      <Row>
                        <Col>
                          <PDFLink src="assets/pdf/campground/HOLD_HARMLESS_AGREEMENT.pdf" text="Camping Hold Harmless Agreement"/>
                        </Col>
                        <Col>
                          <PDFLink src="assets/pdf/campgroundRules.pdf" text="Camping Rules"/>
                        </Col>
                        <Col>
                          <PDFLink src="assets/pdf/Campership Demographics Study.pdf" text="Campership Demographics Study"/>
                        </Col>
                        <Col>
                          <PDFLink src="assets/pdf/campground/Campground_checkoff_list.pdf" text="Campground Checkoff List"/>
                        </Col>
                      </Row>

                      <p>
                        <br/>
                        If you would also like to make reservations for use of the pistol range, please visit our <a className={cs.make_blue} href="/range"><b>Range Information page.</b></a>
                      </p>

                      <p>
                        <br/>
                        For questions, please email <b>propertyscheduling@sccpoa.org</b>
                      </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className={cs.card_border}>
              <Accordion.Toggle className={cs.toggle} as={Card.Header} eventKey="2"> Members & Large Groups </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <br/>
                  <p>
                    If you want to make a reservation with more than your imediate family, please follow the Property Use Donation schedule below:
                    <br/>
                    Member / Tent cabin policy: Each member is allowed to reserve 2 tent cabins at no charge on a space available basis. BBQ area and range reservations are handled separately.
                    <br/>
                  </p>
                  <p>
                    Additional tent cabins can be reserved but will require a Donation per the guidelines listed below:
                    <ul>
                      <li>
                        9 to 12 additional family members/guests = 3 tent cabins will result in a $30 per day/night use fee
                      </li>
                      <li>
                        13 to16 additional family members/guests= 4 tent cabins will result in a $60 per day/night use fee
                      </li>
                      <li>
                         Family groups /events above 16 people will be assessed a nominal fee of $100. depending on the nature of the event, subject to board approval.
                      </li>
                    </ul>
                    <br/>

                    <a href="https://www.google.com/calendar/embed?src=hracing16%40gmail.com&ctz=America/Los_Angeles"> Please check the SCCPOA calendar for available dates. <FontAwesomeIcon className={cs.make_blue} icon={'calendar-alt'} /></a>

                  </p>

                  <Row>
                    <Col>
                      <PDFLink src="assets/pdf/campground/HOLD_HARMLESS_AGREEMENT.pdf" text="Camping Hold Harmless Agreement"/>
                    </Col>
                    <Col>
                      <PDFLink src="assets/pdf/campgroundRules.pdf" text="Camping Rules"/>
                    </Col>
                    <Col>
                      <PDFLink src="assets/pdf/Campership Demographics Study.pdf" text="Campership Demographics Study"/>
                    </Col>
                    <Col>
                      <PDFLink src="assets/pdf/campground/Campground_checkoff_list.pdf" text="Campground Checkoff List"/>
                    </Col>
                  </Row>

                  <p>
                    <br/>
                    If you would also like to make reservations for use of the pistol range, please visit our <a className={cs.make_blue}  href="/range"><b>Range Information page.</b></a>
                  </p>

                  <p>
                    <br/>
                    For questions, please email <b>propertyscheduling@sccpoa.org</b>
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card className={cs.card_border}>
              <Accordion.Toggle className={cs.toggle} as={Card.Header} eventKey="3"> Non Member - Organizations </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                <p>
                 <a href="https://www.google.com/calendar/embed?src=hracing16%40gmail.com&ctz=America/Los_Angeles"> Please check the SCCPOA calendar for available dates. <FontAwesomeIcon className={cs.make_blue} icon={'calendar-alt'} /></a>
                </p>
                  

                  <Row>
                    <Col>
                      <PDFLink src="assets/pdf/2017 Camping HOLD HARMLESS AGREEMENT.pdf" text="Camping Hold Harmless Agreement"/>
                    </Col>
                    <Col>
                      <PDFLink src="assets/pdf/campgroundRules.pdf" text="Camping Rules"/>
                    </Col>
                    <Col>
                      <PDFLink src="assets/pdf/Campership Demographics Study.pdf" text="Campership Demographics Study"/>
                    </Col>
                    <Col>
                      <PDFLink src="assets/pdf/Campground checkoff list.pdf" text="Campground Checkoff List"/>
                    </Col>
                  </Row>

                  <p>
                    <br/>
                    If you would also like to make reservations for use of the pistol range, please visit our <a className={cs.make_blue} href="/range"><b>Range Information page.</b></a>
                  </p>

                  <p>
                    <br/>
                    For questions, please email <b>propertyscheduling@sccpoa.org</b>
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>

            <div align="center" >
              <a href="assets/images/campground/campground site map.jpg">
                <img className={cs.img} src={CampgroundSiteMap}/>
              </a>
            </div>
            
        </Container>
        <Donations/>
        <Footer/>
      </div>
    )
    
  }
}

export default CampgroundReservation;
