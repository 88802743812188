import React, { Component } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
import cs from "./header.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import sccpoaLogo from "../../assets/images/header/sccpoa_logo.png";
import sccpoaLogo from "../../assets/images/60YearAnniversary/60th_Anniversary_logo.png";


class Header extends Component {
  render() {
    return (
      <>
        <div className={cs.header_logo_title_container}>
          <a id={cs.SCCPOA_logo} href={"/home"}>
            <img id={cs.SCCPOA_img} src={sccpoaLogo} alt={"Sccpoa Logo"} />
            <div>
              <div id={cs.SCCPOA_title}>
                Santa Clara County Peace Officers' Association
              </div>
              <div className={cs.SCCPOA_subtitle}>
                {/* Celebrating 60 Years */}
              </div>
            </div>
          </a>
        </div>
        <Navbar sticky={"top"} bg="dark" variant="dark" expand="lg">
          
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              <Nav.Link className={cs.nav_item_header} href="/">
                Home
              </Nav.Link>
              <NavDropdown className={cs.nav_item_header} title="About" id="basic-nav-dropdown">
                <NavDropdown.Item className={cs.nav_item} href="/about">
                  About SCCPOA
                </NavDropdown.Item>
                <NavDropdown.Item className={cs.nav_item} href="/aboutSCCPOF">
                  About SCCPOF
                </NavDropdown.Item>
                <NavDropdown.Item className={cs.nav_item} href="/letterFromThePresident">
                  Letter From The President
                </NavDropdown.Item>
                <NavDropdown.Item className={cs.nav_item} href="/boardMembers">
                  Board Members
                </NavDropdown.Item>
                <NavDropdown.Item className={cs.nav_item} href="/appreciation">
                  Appreciation
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                className={cs.nav_item_header}
                title="Support Us"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item className={cs.nav_item} href="/60th.anniversary.event">
                  60th Anniversary Event
                </NavDropdown.Item>
                <NavDropdown.Item className={cs.nav_item} href="/turkeyShootingEvent">
                  Turkey Shooting Event
                </NavDropdown.Item>
                <NavDropdown.Item className={cs.nav_item} href="/raffle">
                  Raffle
                </NavDropdown.Item> 
                <NavDropdown.Item className={cs.nav_item} href="/merchandise">
                  Merchandise
                </NavDropdown.Item>
                <NavDropdown.Item className={cs.nav_item} href="/directDonationsSCCPOA">
                  Direct Donations, SCCPOA
                </NavDropdown.Item>
                <NavDropdown.Item className={cs.nav_item} href="/directDonationsSCCPOF">
                  Direct Donations, SCCPOF
                </NavDropdown.Item>
                <NavDropdown.Item className={cs.nav_item} href="/volunteer">
                  Volunteer
                </NavDropdown.Item>
                
                
                {/* <NavDropdown.Item className={cs.nav_item} href="/charitableAssociation">
                  Fundraising Charitable Association, SCCPOA
                </NavDropdown.Item>
                <NavDropdown.Item className={cs.nav_item} href="/charitableFoundation">
                  Fundraising Charitable Foundation, SCCPOF
                </NavDropdown.Item> */}

                {/*              <NavDropdown.Item
                  className={cs.nav_item}
                  href="https://smile.amazon.com/gp/chpf/homepage/ref=smi_chpf_redirect?ie=UTF8&ein=82-5444902&ref_=smi_ext_ch_82-5444902_cl"
                >
                  Amazon Smile
                </NavDropdown.Item>*/}
              </NavDropdown>

              <NavDropdown className={cs.nav_item_header} title="Property" id="basic-nav-dropdown">
                <NavDropdown.Item className={cs.nav_item} href="/campground">
                  Camping Information
                </NavDropdown.Item>
                <NavDropdown.Item className={cs.nav_item} href="/range">
                  Range Information
                </NavDropdown.Item>
                <NavDropdown.Item className={cs.nav_item} href="/roadConditions">
                  Road Conditions
                </NavDropdown.Item>
                <NavDropdown.Item
                  className={cs.nav_item}
                  href="https://www.google.com/maps/place/Santa+Clara+County+Peace+Officers+Association/@37.2433398,-122.1008326,12.83z/data=!4m5!3m4!1s0x0:0xc2be1fb7f3c27e98!8m2!3d37.249682!4d-122.0895582?hl=en"
                >
                  Directions
                </NavDropdown.Item>
              </NavDropdown>

              <NavDropdown
                className={cs.nav_item_header}
                title="Memberships"
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item className={cs.nav_item} href="/newAndRenewal">
                  New & Renewal Memberships
                </NavDropdown.Item>
                <NavDropdown.Item className={cs.nav_item} href="/addInsurance">
                  AD&D Insurance
                </NavDropdown.Item>
                {/* <NavDropdown.Item className={cs.nav_item} href="/giftCertificateMemberships">
                  Gift Certificate Memberships
                </NavDropdown.Item> */}
              </NavDropdown>
         
              <Nav.Link className={cs.nav_item_header} href="/contact">
                Contact Us
              </Nav.Link>
              <Nav.Link
                className={cs.nav_item_header}
                href="https://www.google.com/calendar/embed?src=hracing16%40gmail.com&ctz=America/Los_Angelest"
              >
                <FontAwesomeIcon
                  className={cs.nav_item_header_calendar}
                  icon={"calendar-alt"}
                />
              </Nav.Link>
              <Nav.Link className={cs.nav_item_header} href="https://www.facebook.com/SCCPOA/">
                <FontAwesomeIcon
                  className={cs.nav_item_header_facebook}
                  icon={["fab", "facebook-f"]}
                  size="lg"
                />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </>
    );
  }
}

export default Header;
