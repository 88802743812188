import React, {Component} from 'react';

import Header from '../../Common/Components/Header/header.jsx';
import Donations from '../../Common/Components/DonationPanel/donationPanel.jsx'
import Footer from '../../Common/Components/Footer/footer.jsx'
import PageTitle from '../../Common/Components/PageTitle/pageTitle.jsx'
import cs from './about.module.css';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import Emblems from '../../Common/assets/images/about/all agency.jpg';
import YouthOutreachGroupImg from '../../Common/assets/images/youthOutreachGroup.jpg';
import YouthGroupImg from '../../Common/assets/images/about/youthGroup.JPG';
import History1 from '../../Common/assets/images/history/2.jpg';
import History2 from '../../Common/assets/images/history/3.jpg';
import History3 from '../../Common/assets/images/history/4.jpg';
import History4 from '../../Common/assets/images/history/5.jpg';
import History5 from '../../Common/assets/images/history/6.jpg';
import History6 from '../../Common/assets/images/history/7.jpg';
import History7 from '../../Common/assets/images/history/8.jpg';
import History8 from '../../Common/assets/images/history/9.jpg';
import History9 from '../../Common/assets/images/history/10.jpg';
import History10 from '../../Common/assets/images/history/11.jpg';
import History11 from '../../Common/assets/images/history/12.jpg';
import History12 from '../../Common/assets/images/history/13.jpg';
//import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import 'react-tabs/style/react-tabs.css';
import Button from '../../Common/Components/Button/button'
import Accordion from 'react-bootstrap/Accordion'
import Card from 'react-bootstrap/Card'
import YouTubeEmbed from "../../Common/Components/YouTubeEmbed/YouTubeEmbed.jsx";
import NewMemberMainImg from "../../Common/assets/images/newMemberMainImg.JPG"

class About extends Component {
  render(){
    return (
    	<div >
        <Header/>
        {/*<img class={cs.emblem_img} src={Emblems}/>*/}
        <Container>
          <PageTitle title="About The SCCPOA"/>
          <Row className="justify-content-md-center">
            <Col>
              <span className={cs.section_header}></span>
              <p> 
                <b>The Santa Clara County Peace Officers’ Association (SCCPOA)</b>, founded in 1965, is primarily composed of sworn law enforcement and Peace Officers from city, county, state, and federal agencies in and around Santa Clara County. A Board of Directors representing each agency governs the association. 
              </p>
              <p>
                SCCPOA is a 501(c)(4) nonprofit community service organization assisting local youth programs, local service organizations, and local charities.
              </p>

              <span className={cs.section_header}>Mission Statement:</span>
              <p> 
                The Santa Clara County Peace Officers’ Association (SCCPOA) provides services to
                many organizations within Santa Clara County, including underprivileged,
                disadvantaged, and/or at-risk youth, in order to encourage positive interaction with law
                enforcement officers while using our property.
              </p>

              <hr/>

              <Accordion defaultActiveKey="1">
                <Card className={cs.card_boarder}>
                  <Accordion.Toggle className={cs.toggle} as={Card.Header} eventKey="1">Comprised of These Agencies </Accordion.Toggle>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <img className={cs.img} src={Emblems}/>
                      <p>
                        The Santa Clara County Peace Officers’ Association includes 
                        Peace Officers from agencies within Santa Clara County and around the Bay Area such as: 
                        <br/><br/>
                        
                        Adult Parole,                                                                                                
                        Alcohol Tobacco Firearms,                         
                        Barna P.I. & Security,                                            
                        Berkeley Fire,                            
                        Berkeley PD,                                                                   
                        Brisbane PD,                       
                        Burlingame PD,                                                      
                        CA Dept. of Insur.-Fraud Div,             
                        CA DOJ Bureau of Firearms,                
                        CA.Dept.Forestry,                 
                        Campbell PD,                                                    
                        Ca. Highway Patrol,                                                                   
                        City of Pleasanton,                                                
                        Colma Police Dept.,                       
                        Daly City PD,                                             
                        Dietz Associates,                                                   
                        DMV Investigations,                                               
                        East PAPD,                                                                               
                        Federal Bureau Investigations,                                                                    
                        Federal Air Marshal Service,      
                        Foothill DeAnza PD,                
                        Foster City PD,                           
                        Gilroy PD,                                                 
                        Hillsborough  PD,                                            
                        Lake Arthur PD (New Mexico),              
                        Los Altos PD,                                                                  
                        Los Gatos PD,                                                                 
                        Menlo Park PD,                            
                        Milpitas PD,                                                     
                        Moffett Field,                            
                        Morgan Hill PD,                                           
                        Mountain View PD,                                                 
                        Napa State Hospital PD,                   
                        NASA,                                                                         
                        Pacifica PD,                                                   
                        Palo Alto PD,                                                                  
                        Post Office Investigator,              
                        Redwood city PD,                                             
                        Santa Clara County Sheriff Dept.,                                                                      
                        San Bruno PD,                                                  
                        San Diego District Atty.,                             
                        San Francisco PD,                         
                        San Jose Community College D,             
                        San Jose Evergreen District,              
                        San Jose Fire Dept.,                                         
                        San Mateo PD,                             
                        San Mateo Sheriff Office,                            
                        Santa Clara Co. Fire,                     
                        Santa Clara PD,                    
                        Santa Clara Superior court,               
                        Santa Clara County Unified School Dist.,                  
                                                                      
                        Santa Clara County Probation,                                                             
                        Santa Clara County District Atty. Office,                                 
                        Santa Clara County Fire Dept.,                                                                                                                     
                        San Francisco Medical Examiner,                                               
                        San Francisco PD,                                
                        San Jose fire Dept.,
                        San Jose Police Dept.,                                                                  
                        San Jose State University Police,                                
                                                                            
                        So. San Francisco PD,                                
                        Soledad PD,                                                        
                        Stanford Dept. Public Safety,                             
                        STATE PAROLE,                       
                        Sunnyvale Dept. of Public Safety,                                         
                        TSA-FFDO United Airlines,                 
                        Union City PD,                    
                        US Defense Criminal Invest service,       
                        US Marshal,                                                             
                        US Marshals Service,                      
                        US Postal Inspector,                     
                        US Pretrial Services Agency,              
                        Ventura County S/O, and                                                    
                        West Valley-Mission College PD.      
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
              </Accordion>

              <hr/>
               <p className={cs.section_header}>Youth Outreach:</p>
              <Row>

                <Col sm={3}> <img class={cs.youth_outreach_img} src={YouthOutreachGroupImg} ></img> </Col>
                <Col sm={9}>
                  <p>
                    The SCCPOA Board Members organize and volunteer with our Campership Program, which involves structured group outings where at-risk youth camp at our property. These campouts promote positive interaction between these at-risk youth and law enforcement officers. This includes youngsters who may even have had a brush with the law, and may not have positive feelings about law enforcement. Officers from different agencies volunteer at the campouts, allowing closer, more informal contact between these youngsters and a law enforcement officer.
                  </p>
                  <p>
                    For the most part, these youngsters come from our local satellite group homes. Chaperones from the group home are required to attend. The Girl Scouts, Boy Scouts, and Cub Scouts use the property on a regular basis. Our campground and property is open all year.
                    <a href={"/youthOutreach"} className={cs.more_info}>{" "}Additional Information</a>
                    {/* <div className={cs.reservation_button}><Button text={"More Information"} redirection={"/youthOutreach"}/></div> */}
                   </p> 
                </Col>
              </Row>
            </Col>
          </Row>
          <br/>
          <img class={cs.youth_group_img} src={YouthGroupImg} ></img>

          <div className={cs.tabs}>
          {/*
            
          */}


          </div>

          <hr/>
          
          <p className={cs.section_header}>
            Property Details:
          </p>
          <p>
            The Santa Clara County Peace Officers’ Association owns six contiguous parcels of land totaling approximately 171 acres located off Highway 9, in the mountains above the City of Saratoga. This property, maintained solely by our members, has a campground consisting of 9 tent cabins, boys’ and girls’ bathrooms complete with hot showers and flushable toilets, a campfire amphitheater, a large BBQ area, many open field areas, and a 50-yard pistol range used not only by law enforcement officers to practice their marksmanship, but also by many Scouts to earn their shooting merit badges.
          </p>
           
           <img className={cs.img} src={NewMemberMainImg}/>

          <hr/>
          <p className={cs.section_header}>
            SCCPOA History:
          </p>
          <div className={cs.youtube_video_container}>
            <YouTubeEmbed videoId="OwFpbXhcP9k?si=GhKtz9aiEW8Zi-h3"/>
          </div>

          {/* <p className={cs.section_header}> History: </p>
          <Carousel>
            <Carousel.Item align="center">
              <img  className={cs.carousel_img} src={History1} alt="First slide"/>
            </Carousel.Item>
            <Carousel.Item align="center">
              <img className={cs.carousel_img} src={History2} alt="Second slide"/>
            </Carousel.Item>
            <Carousel.Item align="center">
              <img className={cs.carousel_img} src={History3} alt="Third slide"/>
            </Carousel.Item>
            <Carousel.Item align="center">
              <img className={cs.carousel_img} src={History4} alt="Fourth slide"/>
            </Carousel.Item>
            <Carousel.Item align="center">
              <img className={cs.carousel_img} src={History5} alt="Fifth slide"/>
            </Carousel.Item>
            <Carousel.Item align="center">
              <img className={cs.carousel_img} src={History6} alt="Sixth slide"/>
            </Carousel.Item>
            <Carousel.Item align="center"> 
              <img className={cs.carousel_img} src={History7} alt="Seventh slide"/>
            </Carousel.Item>
            <Carousel.Item align="center"> 
              <img className={cs.carousel_img} src={History8} alt="Eighth slide"/>
            </Carousel.Item>
            <Carousel.Item align="center">
              <img className={cs.carousel_img} src={History9} alt="Nineth slide"/>
            </Carousel.Item>
            <Carousel.Item align="center">
              <img className={cs.carousel_img} src={History10} alt="Tenth slide"/>
            </Carousel.Item>
            <Carousel.Item align="center">
              <img className={cs.carousel_img} src={History11} alt="Eleventh slide"/>
            </Carousel.Item>
            <Carousel.Item align="center">
              <img className={cs.carousel_img} src={History12} alt="Twelfth slide"/>
            </Carousel.Item>
            
          </Carousel> */}

        </Container>
        <Donations/>
        <br/>
        <Footer/>
    	</div>
    )
  }
}

export default About;