import React, { Component } from "react";
import cs from "../membership.module.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Select from "react-select";
import rafflePdfImg from "../../../assets/images/raffle/raffle_flier_2025.jpg";


class RaffleTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isHidden: true,
      showRaffleTicketSelector: false,
    };
    this.toggleTextBlockHidden = this.toggleTextBlockHidden.bind(this);
    this.handleRaffleYesCheckboxChange = this.handleRaffleYesCheckboxChange.bind(this);
    this.handleRaffleNoCheckboxChange = this.handleRaffleNoCheckboxChange.bind(this);
  }

  toggleTextBlockHidden() {
    this.setState({ isHidden: !this.state.isHidden });
  }

  handleRaffleYesCheckboxChange(event) {
    let cartDetails = this.props.cartDetails;
    cartDetails.raffleTicketDetails.totalTickets = 1;
    this.setState({showRaffleTicketSelector: true})
    this.props.setParentState({
      cartDetails,
      raffleTicketSelected: true,
      raffleButtonDisabled: false
    });
  }

  handleRaffleNoCheckboxChange(event) {
    let cartDetails = this.props.cartDetails;
    cartDetails.raffleTicketDetails.totalTickets = 0;
    this.setState({showRaffleTicketSelector:false})
    this.props.setParentState({
      cartDetails,
      raffleTicketSelected: true,
      raffleButtonDisabled: false
    });
  }

  ticketOptionSelected(totalTickets) {
    let cartDetails = this.props.cartDetails;
    if (totalTickets == 0) {
      cartDetails.raffleTicketDetails = { totalTickets:undefined, price: 100 };
    } else {
      cartDetails.raffleTicketDetails = { totalTickets: totalTickets.value, price: 100 };
    }
    this.props.setParentState({ cartDetails });
  }

  render() {
    let { isHidden, showRaffleTicketSelector } = this.state;

    return (
      <div>
        <Row>
          <Col sm={12} md={8}>
            <p className={cs.step_header}>
              Optional - Raffle Ticket Purchase, Priced at $100/Ticket
            </p>
            <p>
              Each raffle ticket purchased will be entered into the Annual Road
              Maintenance Fund Raffle. 100% of the raffle proceeds are placed into a
              designated road fund. You will receive your raffle tickets along with
              your membership packet by email. For more details, see the Road
              Maintenance Fund Raffle Flier PDF below.
            </p>
            <div className={cs.hiddenTextToggle} onClick={this.toggleTextBlockHidden}>
              {isHidden ? "See" : "Hide"} additional tax deduction information
              {isHidden ? " here" : ""}.
            </div>

            {!isHidden && (
              <div className={cs.hiddenTextBlock}>
                <p>
                  Note: The SCCPOA is a tax-exempt organization under Section 501
                  c(4) of the Internal Revenue Code. This gift is considered
                  tax-deductible as a charitable contribution for Federal Income Tax
                  purposes. Federal Tax ID # 94-6122042.
                </p>
                <p>
                  Any charitable donation is 100% Tax Deductible! No funds received
                  by the association are used outside the County. You may be able to
                  deduct your donation as trade or business expenses, if ordinary
                  and necessary in the conduct of the taxpayer’s business. Please
                  consult your tax advisor as to how your support may be deducted.
                  If you need a letter from us please email{" "}
                  <b>secretary@sccpoa.org</b>.
                </p>
                <p>
                  *Donation refund policy: Due to the nature of donations, refunds
                  are not offered. Secure Checkout is provided through PayPal.
                </p>
                <p>Thank you for your support!</p>
              </div>
            )}

            <Row className={cs.raffleTicketSelectionContainer}>
              <Col md={7}>
                <div>
                  <label className={cs.document_label}>
                    <input
                      type="radio"
                      name="raffleGroup"
                      defaultChecked={false}
                      onChange={this.handleRaffleYesCheckboxChange}
                    />
                    <span></span>
                  </label>
                  <span>Yes, I would like to participate in the raffle.</span>{" "}
                </div>
                <div>
                  <label className={cs.document_label}>
                    <input
                      type="radio"
                      name="raffleGroup"
                      onChange={this.handleRaffleNoCheckboxChange}
                    />
                    <span></span>
                  </label>
                  <span>No, I would not like to participate at this time.</span>{" "}
                </div>
              </Col>
              <Col md={3}>
                {showRaffleTicketSelector && (
                  <div>
                    <div className={cs.payment_heading}> Select Ticket Quantity Below: </div>
                    <Select
                      defaultValue={{ value: 1, label: "1 x $100" }}
                      options={[
                        { value: 1, label: "1 x $100" },
                        { value: 2, label: "2 x $200" },
                        { value: 3, label: "3 x $300" },
                        { value: 4, label: "4 x $400" },
                        { value: 5, label: "5 x $500" },
                        { value: 6, label: "6 x $600" },
                        { value: 7, label: "7 x $700" },
                        { value: 8, label: "8 x $800" },
                        { value: 9, label: "9 x $900" },
                        { value: 10, label: "10 x $1000" },
                      ]}
                      onChange={this.ticketOptionSelected.bind(this)}
                    />
                  </div>
                )}
              </Col>
            </Row>
          </Col>    
          <Col sm={12} md={4}>
            <div className={cs.pdf_img_container}>
              <a href="assets/pdf/raffle/raffle_flier_2025.pdf" target="_blank">
                <img className={cs.pdf_img} src={rafflePdfImg} />
              </a>
            </div>
          </Col>      
        </Row> 
      </div>
    );
  }
}

export default RaffleTab;
