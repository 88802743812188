import React, {Component} from 'react';
import cs from './donateToday.module.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ReactPayPal from "../paypal/ReactPayPal.jsx";

class DonateToday extends Component {
  constructor(props){
    super(props)
    this.state={
      donationAmount: 0
    }
    this.preSetDonationSelected = this.preSetDonationSelected.bind(this)
    this.donationAmountChange = this.donationAmountChange.bind(this)
  }

  preSetDonationSelected(e){
    this.setState({donationAmount: e.target.value})
  }
  donationAmountChange(e){
    this.setState({donationAmount: e.target.value})
  }

  calculateTotal(){
    return this.state.donationAmount
  }

  getDescription(){
    return "SCCPOA Direct Donations"
  }

  render(){
    let donationAmount = this.state.donationAmount 

    return (
      <div>
            <h3 align="left"> <b>{this.props.title||"Donate Here Today!"}</b></h3>
{/* 
            <div>
                <div className={cs.payment_heading+" "+cs.bold}>
                  Select Donation Amount Below:
                </div>
                <Row>
                  <Col md={4}>
                    <label className={cs.document_label}>
                      <input
                        type="radio"
                        onChange={this.preSetDonationSelected}
                        value={1000}
                        name="group1"
                      />{" "}
                      <span>$1000</span>
                    </label>{" "}
                  </Col>
                  <Col md={4}>
                    <label className={cs.document_label}>
                      <input
                        type="radio"
                        onChange={this.preSetDonationSelected}
                        value={500}
                        name="group1"
                      />{" "}
                      <span>$500</span>
                    </label>{" "}
                  </Col>
                  <Col md={4}>
                    <label className={cs.document_label}>
                      <input
                        type="radio"
                        onChange={this.preSetDonationSelected}
                        value={250}
                        name="group1"
                      />{" "}
                      <span>$250</span>
                    </label>{" "}
                  </Col>
                  <Col md={4}>
                    <label className={cs.document_label}>
                      <input
                        className={cs.document_label}
                        type="radio"
                        onChange={this.preSetDonationSelected}
                        value={100}
                        name="group1"
                      />{" "}
                      <span>$100</span>
                    </label>{" "}
                  </Col>
                  <Col md={4}>
                    <label className={cs.document_label}>
                      <input
                        type="radio"
                        onChange={this.preSetDonationSelected}
                        value={50}
                        name="group1"
                      />{" "}
                      <span>$50</span>
                    </label>{" "}
                  </Col>
                  <Col md={4}>
                    <label className={cs.document_label}>
                      <input
                        type="radio"
                        onChange={this.otherSelected}
                        name="group1"
                      />{" "}
                      <span>other</span>
                    </label>{" "}
                  </Col>
                  <Col md={12}>
                    <span>
                      <span className={cs.payment_heading}>
                        Please enter desired amount:
                      </span>{" "}
                      $
                      <input
                        value={donationAmount}
                        onChange={this.donationAmountChange}
                        className={cs.other_text_field}
                      />
                    </span>
                  </Col>
                </Row>
            </div> */}

            <Row >
              <Col >

                {/* <p className={cs.bold}>Payments made by PayPal:</p>
                <ReactPayPal
                  className={cs.deactivated}
                  calculateTotal={this.calculateTotal.bind(this)}
                  getPaymentDescription={this.getDescription.bind(this)}
                /> */}

                <p className={cs.bold}>Pay by PayPal/Credit Card + Paypal Fees:</p>
                  <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
                  <input type="hidden" name="cmd" value="_s-xclick"/>
                  <input type="hidden" name="hosted_button_id" value={this.props.paypalId}/>
                  <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!"/>
                  <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1"/>
                  </form> 
               

              </Col>
              <Col>
                <div>
                <span className={cs.bold + " " + cs.margin_bottom}> Pay by check to: </span>  
                <p >
                  Santa Clara County Peace Officers' Association 
                  <br/>
                  P.O. Box 4629 
                  <br/>
                  Mountain View, CA 94040 
                  <br/>
                </p>
                </div>
                
              </Col>
            </Row>
            {
          //      <table >
          //     <tr>
          //       <th>Payments made by PayPal:</th>
          //       <th>Payments made by check to:</th>
          //     </tr>

          //     <tr>
          //       <td>
          //         <form action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_top">
          //         <input type="hidden" name="cmd" value="_s-xclick"/>
          //         <input type="hidden" name="hosted_button_id" value="7VDFM7LYQMVHN"/>
          //         <input type="image" src="https://www.paypalobjects.com/en_US/i/btn/btn_donateCC_LG.gif" border="0" name="submit" alt="PayPal - The safer, easier way to pay online!"/>
          //         <img alt="" border="0" src="https://www.paypalobjects.com/en_US/i/scr/pixel.gif" width="1" height="1"/>
          //         </form>
          //       </td>
          //       <td>
          //         Santa Clara County Peace Officer's Association 
          //         <br/>
          //         P.O. Box 4629 
          //         <br/>
          //         Mountain View, Ca 94040 
          //         <br/>
          //       </td>
          //     </tr>
          // </table>
            }
           
          <br/>
            <p>
              Note: SCCPOA is a tax-exempt organization under Section 501(c)(4) of the Internal Revenue Code. This gift is considered tax deductible as a charitable contribution for federal income tax purposes. Federal Tax ID 94-6122042.           
            </p>
            <p>
              Any charitable donation is 100% tax deductible! No funds received by the association are used outside the county. You may be able to deduct your donation as a trade or business expense, if ordinary and necessary in the conduct of the taxpayer’s business. Please consult your tax advisor as to how your support may be deducted.
            </p>
            <p>
              *Donation refund policy: Due to the nature of donations, refunds are not offered. Secure checkout is provided through PayPal.
            </p>
            <p>
              If you have any questions please email <b>fundraising@sccpoa.org</b>.
            </p>
          
  
      </div>
    )
    
  }
}

export default DonateToday;
