import React, { Component } from "react";
import Header from "../../Common/Components/Header/header.jsx";
import Donations from "../../Common/Components/DonationPanel/donationPanel.jsx";
import Footer from "../../Common/Components/Footer/footer.jsx";
import PageTitle from "../../Common/Components/PageTitle/pageTitle.jsx";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import cs from "./merchandise.module.css";
import shirtImg from "../../Common/assets/images/merchandise/tshirt.jpg";
import hatImg from "../../Common/assets/images/merchandise/hat.jpg";
import ReactPayPal from "../../Common/Components/paypal/ReactPayPal.jsx";

class Merchandise extends Component {
  constructor(props){
    super(props)
    this.state={
      tshirtOptions:[
        {itemName:"Small", abbrevItemName:"S", totalAmount:0, price:25},
        {itemName:"Medium", abbrevItemName:"M", totalAmount:0, price:25},
        {itemName:"Large", abbrevItemName:"L", totalAmount:0, price:25},
        {itemName:"XLarge", abbrevItemName:"XL", totalAmount:0, price:25},
        {itemName:"2XLarge", abbrevItemName:"2XL", totalAmount:0, price:25},
        {itemName:"3XLarge", abbrevItemName:"3XL", totalAmount:0, price:25}
      ],
      hatDetails:{itemName:"Hat", abbrevItemName:"Ht", totalAmount:0, price:25}
    }

    this.merchandiseAmount = this.merchandiseAmount.bind(this)
    this.calculatePaypalTotalWithTax = this.calculatePaypalTotalWithTax.bind(this);
  }

  handleTshirtAmountChange(selectedTshirtData, e){
    let newTshirtOptions = this.state.tshirtOptions.map(tshirtData=>{
      if(tshirtData.itemName===selectedTshirtData.itemName){
        let numshirtsSelected = e.target.value>=0 ? e.target.value : 0
        tshirtData.totalAmount = numshirtsSelected
      }
      return tshirtData
    })
    this.setState({tshirtOptions:newTshirtOptions})
  }

  handleHatAmountChange(e){
    let numHatsSelected = e.target.value>=0 ? e.target.value : 0
    let newHatDetails = this.state.hatDetails
    newHatDetails.totalAmount = numHatsSelected
    this.setState({hatDetails:newHatDetails})
  }

  merchandiseAmount(){
    let {tshirtOptions, hatDetails} = this.state
    let tshirtTotalAmount = tshirtOptions.reduce((total, tshirtData)=>total + parseInt(tshirtData.totalAmount*tshirtData.price), 0)
    let hatTotalAmount = parseInt(hatDetails.totalAmount * hatDetails.price)
    return tshirtTotalAmount + hatTotalAmount
  }

  getPaymentDescription(){
    let {tshirtOptions, hatDetails} = this.state
    let tshirtDescription = tshirtOptions.filter(tshirtData=>tshirtData.totalAmount>0)
    tshirtDescription = tshirtDescription.reduce((description, tshirtData)=>description+(description?",":"")+parseInt(tshirtData.totalAmount)+"-"+tshirtData.abbrevItemName+parseInt(tshirtData.totalAmount*tshirtData.price), "")
    let hatDescription = hatDetails.totalAmount>0 ?  ","+parseInt(hatDetails.totalAmount)+hatDetails.abbrevItemName+parseInt(hatDetails.totalAmount*hatDetails.price) : ""
    return tshirtDescription + hatDescription
  }

  calculatePaypalTotalWithTax(total) {
    return Math.round(this.paypalfees(total) * 100) / 100;
  }

  toTwoDecimal(num) {
    return parseFloat(num.toString()).toFixed(2);
  }

  paypalfees(amount) {
    amount += 0.49;
    return amount / (1 - 0.0349);
  }

  render() {
    let {tshirtOptions, hatDetails} = this.state

    return (
      <div>
        <Header />
        <Container>
          <PageTitle title="Merchandise" />
          <p> Merchandise to be picked up at the 60th Anniversary event, the SCCPOA board meeting, work party, or at a scheduled time. </p>

          <div className={cs.merchandise_container}>
            <Row>
              <Col sm={12} md={7}>
                <div className={cs.merch_img_container}>
                  <img className={cs.merch_img} src={shirtImg} />
                </div>
                <div className={cs.merch_title}>Adult Size T-shirts - $25</div> 

                <div className={cs.tshirt_pricing_options_container}>
                  {tshirtOptions.map((tshirtData, i)=>{
                    return (
                      <div key={i} className={cs.tshirt_pricing_container}>
                        <div className={cs.tshirt_size}> {tshirtData.itemName} </div>
                        <div><input type="number" value={tshirtData.totalAmount} onChange={this.handleTshirtAmountChange.bind(this, tshirtData)} className={cs.merch_input}></input></div>
                      </div>
                    )
                  })}
                </div>

              </Col>    
              <Col sm={12} md={5}>
                <div className={cs.merch_img_container}>
                  <img className={cs.merch_img} src={hatImg} />
                </div>
                <div className={cs.merch_title}>Hat - $25</div> 

                <div className={cs.tshirt_pricing_options_container}>
                  <div className={cs.tshirt_pricing_container}>
                    <div className={cs.tshirt_size}> {hatDetails.itemName} </div>
                    <div><input type="number" value={hatDetails.totalAmount} onChange={this.handleHatAmountChange.bind(this)} className={cs.merch_input}></input></div>
                  </div> 
                </div>
              </Col>      
            </Row> 

            {this.merchandiseAmount()>0 &&
              <div>
                <h4 className={cs.payment_total}>Payment</h4>
                <hr/>
                <Row>
                  <Col>
                    <ReactPayPal
                      calculateTotal={() => this.calculatePaypalTotalWithTax( this.merchandiseAmount() )}
                      getPaymentDescription={this.getPaymentDescription.bind(this)}
                      toTwoDecimal={this.toTwoDecimal}
                    />
                  </Col>
                  <Col>
                    <h4 className={cs.header}>
                      If paid by check ($
                      {this.toTwoDecimal(this.merchandiseAmount())})
                    </h4>
                    <h4 className={cs.header}>Remit payment to:</h4>
                    <p className={cs.check_content}>
                      Santa Clara County Peace Officer's Association
                      <br />
                      P.O. Box 4629
                      <br />
                      Mountain View, Ca 94040
                    </p>
                  </Col>
                </Row>
              </div>
            }

          </div>

        </Container>
        <Donations />
        <br />
        <Footer />
      </div>
    );
  }
}

export default Merchandise;
